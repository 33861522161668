<template>
  <Component :is="componentName" v-if="hero" :hero="hero" />
</template>

<script setup>
import { useChangeCase } from '@vueuse/integrations/useChangeCase'

const props = defineProps({
  heros: { type: Array, default: () => [] },
})

const hero = computed(() => {
  if (!props.heros.length) {
    return null
  }

  return props.heros[0]
})

const componentName = computed(() => {
  if (!hero.value) {
    return null
  }

  const pascalCase = useChangeCase(hero.value.type, 'pascalCase')

  return pascalCase.value
})
</script>
